import React, { useEffect, useState } from "react";
import { Row, Col, Modal, Form, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import moment from "moment-timezone";
import { toast } from "react-toastify";
import { useQueryClient } from "react-query";
import numeral from "numeral";

import { useApi } from "../../../hooks/useApi";
import ServicesDisplayList from "./ServicesDisplayList";
import BookingEditForm from "./BookingEditForm";
import BookingCancelForm from "./BookingCancelForm";
import { nl2br } from "../../../lib/stringHelpers";

const getProfile = (booking) => {
  if (!booking) {
    return { name: "Unknown" };
  }

  let profile = booking.customer.customer.profiles.find(
    (p) => p._id === booking.jobRequest.requestFor
  );

  if (!profile) {
    profile = booking.customer.customer.profiles[0];
  }

  return profile;
};

const BookingDetailsModal = ({ show, toggle, booking }) => {
  const [events, setEvents] = useState(null);
  const [action, setAction] = useState("");
  const api = useApi({ onError: (err) => toast.error(err.message) });
  const profile = getProfile(booking);
  const queryClient = useQueryClient();

  useEffect(() => {
    if (booking) {
      api.call(
        `/admin/job-requests/event/${booking._id}/events`,
        "get",
        undefined,
        undefined,
        (res) => {
          setEvents(res);
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [booking]);

  const renderEvents = () => {
    if (events === null) {
      return <p>Loading history timeline...</p>;
    }

    return (
      <div>
        {events.map((event, idx) => {
          return (
            <div key={idx} className="mb-3">
              <p className="text-muted mb-0">
                {moment(event.date).format("MM/DD/YYYY h:mm A")}
              </p>
              <p>{nl2br(event.label)}</p>
            </div>
          );
        })}
      </div>
    );
  };

  const onHandleActionSelection = (e) => {
    setAction(e.target.value);
  };

  const renderActions = () => {
    const options = [];

    if (booking.isCanceled) {
      return <small>No actions are available.</small>;
    }

    options.push(<option value="">Select an action</option>);
    options.push(<option value="edit">Edit Booking</option>);

    if (!booking.completedOn) {
      // options.push(<option value="complete">Complete Booking</option>);
    }

    if (!booking.holdOn || !booking.completedOn) {
      options.push(<option value="cancel">Cancel Booking</option>);
    }

    return (
      <div className="mb-4">
        <div className="d-flex justify-content-start align-items-center gap-2">
          <label>Actions:</label>
          <Form.Select
            onChange={onHandleActionSelection}
            value={action}
            style={{ width: "auto" }}
          >
            {options}
          </Form.Select>
        </div>
      </div>
    );
  };

  const renderActionForm = () => {
    switch (action) {
      case "edit":
        return (
          <BookingEditForm
            booking={booking}
            onSuccess={() => {
              toggle();
              setAction("");
              queryClient.invalidateQueries({ queryKey: ["admin-bookings"] });
              toast.success("Your booking was successfully updated.");
            }}
            onCancel={() => setAction("")}
          />
        );

      case "cancel":
        return (
          <BookingCancelForm
            booking={booking}
            onSuccess={() => {
              toggle();
              setAction("");
              queryClient.invalidateQueries({ queryKey: ["admin-bookings"] });
              toast.success("Your booking was successfully updated.");
            }}
            onCancel={() => setAction("")}
          />
        );
      default:
        return null;
    }
  };

  const render = () => {
    if (!show || !booking) {
      return null;
    }

    return (
      <>
        {booking.isCanceled ? (
          <div className="alert alert-warning">
            <p className="mb-0">
              This event was canceled on{" "}
              {moment(booking.canceledOn).format("MM/DD/YYYY h:mm A")}.
            </p>
          </div>
        ) : null}
        {renderActions()}
        {renderActionForm()}
        <hr />
        <Row>
          <Col xs={12} sm={6}>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Customer</Form.Label>
                  <p>
                    <Link to={`/admin/users/${booking.customer._id}`}>
                      {booking.customer.name}
                    </Link>
                  </p>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Helper</Form.Label>
                  <p>
                    {booking.helper ? (
                      <Link to={`/admin/users/${booking.helper._id}`}>
                        {booking.helper.name}
                      </Link>
                    ) : (
                      "--"
                    )}
                  </p>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Date</Form.Label>
                  <p className="mb-0">
                    {moment(booking.startDate).format("MM/DD/YYYY")}
                  </p>
                  <p>
                    {moment(booking.startDate).format("h:mm A")} -{" "}
                    {moment(booking.endDate).format("h:mm A")}
                  </p>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Hours</Form.Label>
                  <p>{booking.estimatedTime}</p>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Request For</Form.Label>
                  <p>{profile.name}</p>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Job Status</Form.Label>
                  <p>{booking.jobRequest.jobStatus}</p>
                </Form.Group>
              </Col>
            </Row>

            <Form.Group className="mb-3">
              <Form.Label>Address</Form.Label>
              <p>
                {profile.address.street1}, {profile.address.city},{" "}
                {profile.address.state} {profile.address.zip}
              </p>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Services</Form.Label>
              <ServicesDisplayList
                selectedServices={booking.jobRequest.servicesRequested}
                specializedCare={booking.jobRequest.specializedCare}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Money</Form.Label>
              <Table>
                <thead>
                  <tr>
                    <th>&nbsp;</th>
                    <th className="text-end">Customer</th>
                    <th className="text-end">Helper</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {booking.estimatedTime} hours @{" "}
                      {numeral(
                        booking.helper.helper.hourlyRates.standard
                      ).format("$0,0.00")}
                      /hr
                    </td>
                    <td className="text-end">
                      {numeral(
                        booking._payouts.customerTotal -
                        booking._payouts.customerFee
                      ).format("$0,0.00")}
                    </td>
                    <td className="text-end">
                      {numeral(
                        booking._payouts.helperTotal +
                        booking._payouts.helperFee
                      ).format("$0,0.00")}
                    </td>
                  </tr>
                  <tr>
                    <td>Service Fee</td>
                    <td className="text-end">
                      {(booking.jobRequest.serviceFee.customer || 0.2) * 100}%
                    </td>
                    <td className="text-end">
                      {(booking.jobRequest.serviceFee.helper || 0.2) * 100}%
                    </td>
                  </tr>
                  <tr>
                    <td>Service Cost</td>
                    <td className="text-end">
                      {numeral(booking._payouts.customerFee).format("$0.00")}
                    </td>
                    <td className="text-end">
                      {numeral(booking._payouts.helperFee).format("$0.00")}
                    </td>
                  </tr>
                  <tr>
                    <td>Total</td>
                    <td className="text-end">
                      {numeral(booking._payouts.customerTotal).format(
                        "$0,0.00"
                      )}
                    </td>
                    <td className="text-end">
                      {numeral(booking._payouts.helperTotal).format("$0,0.00")}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Form.Group>
            <br />

            <Form.Group className="mb-3">
              <Form.Label>Stripe</Form.Label>
              <Table>
                <thead>
                  <tr>
                    <th>Type</th>
                    <th>Date</th>
                    <th>Amt</th>
                    <th>Fee</th>
                    <th>Stripe Id</th>
                  </tr>
                </thead>
                <tbody>
                  {!booking.holdTransactionId &&
                    !booking.completedTransactionId ? (
                    <tr>
                      <td colSpan={5}>No billing information available.</td>
                    </tr>
                  ) : null}
                  {booking.holdTransactionId ? (
                    <tr>
                      <td>Pre-Auth</td>
                      <td>{booking.holdOn || "Unknown"}</td>
                      <td>{booking.holdAmount}</td>
                      <td>{booking.holdFee}</td>
                      <td>{booking.holdTransactionId}</td>
                    </tr>
                  ) : null}
                  {booking.completedTransactionId ? (
                    <tr>
                      <td>Capture</td>
                      <td>{booking.completedOn || "Unknown"}</td>
                      <td>{booking.completedAmount}</td>
                      <td>{booking.completedFee}</td>
                      <td>{booking.completedTransactionId}</td>
                    </tr>
                  ) : null}
                </tbody>
              </Table>
            </Form.Group>
          </Col>
          <Col xs={12} sm={6}>
            {renderEvents()}
          </Col>
        </Row>
      </>
    );
  };

  return (
    <Modal show={show} onHide={() => toggle()} size="xl">
      <Modal.Body>{render()}</Modal.Body>
    </Modal>
  );
};

export default BookingDetailsModal;
